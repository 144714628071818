  .cMapdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin:10px;
    z-index: '1 !important';
  }

  .cGenPicker{
    align-items: center;
    text-align: center;
    width: 100%;
    z-index: '99999';
  }

  .cInterceptor{
    align-items: center;
    text-align: center;
    width: 25%;
  }

  .cLoading{
    align-items: center;
    text-align: center;
    width: 100%;
  }
  
  .cInput{
    margin-top: 5px;
    text-align: right !important;
    align-items: right !important;
    border-style:none;
    border-color: gray !important;
    width: 80px;
  }

  .cTable{
    border-radius:10px;
    border-style: solid;
    border-color: lightgray;
    padding: 10px;
    width: 30%;
  }

  .refresh-button {
    margin: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    visibility:visible;
  }

  .refresh-button:hover {
    background-color: #0056b3;
  }