.App-header {
  background-image: linear-gradient(to right, white, #5173b6, white);
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  text-align: center;
  height: 40px;
  border-radius: 10px;
}
.cSpecials{
  background-image: linear-gradient(to right, #5173b6, white);
  text-align: left;
  /*background-color: #5173b6;*/
  font-size: 14px;
  justify-content: left !important;
  align-items: left !important;
  color: white;
}
.cDivleft {
  float: left;
}
.cClear{
  clear:both;
}

.leaflet-container {
  width: 850px;
  height: 850px;
}

