  .cMapdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin:10px;
    z-index: 1 !important;
  }

  .cGenPicker{
    align-items: center;
    text-align: center;
    width: 100%;
    z-index: 99999 !important;
  }

  .cdivInterceptor{
    align-self: center;
    align-items: center;
    text-align: center;
  }

  .cLoading{
    align-items: center;
    text-align: center;
    width: 100%;
  }
  
  .cInput{
    margin-top: 5px;
    text-align: right !important;
    align-items: right !important;
    border-style:none;
    border-color: gray !important;
    width: 80px;
  }

  .cSelector{
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 9999 !important;
}

.mb-3 {
  display: inline-block;
  margin-right: 10px;
  width: 250px;
}

.form-floating > label {
  padding: 0.5rem 0.75rem;
}

.cTableDiv {
  margin: 20px;
  overflow-x: auto;
}