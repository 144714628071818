.cDashboard{
    align-items: center;
    text-align: center;
  }
  .cButtonRight{
    float: right;
    align-items: right;
    text-align: right;
    width: 10%;
  }
  .cSelector{
    align-items: center;
    text-align: center;
    width: 25%;
  }
  .cinput{
    background-color: transparent;
    border:transparent;
    text-align: center;
  }
