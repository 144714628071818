.leaflet-container {
    width: 100%;
    height: 100vh;
}

/* Optional: Add a border and box shadow for better visual appearance */
.map-container {
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} 
.leaflet-popup {
    padding-bottom: 30px; /* adjust as you see fit */
}

.autozoom-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  padding: 8px 16px;
  background-color: white;
  border: 2px solid rgba(0,0,0,0.2);
  border-radius: 4px;
  cursor: pointer;
}

.autozoom-button:hover {
  background-color: #f4f4f4;
}