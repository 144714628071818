.leaflet-container {
    width: 100%;
    height: 70vh;
}

/* Optional: Add a border and box shadow for better visual appearance */
.map-container {
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} 
.leaflet-popup {
    padding-bottom: 24px; /* adjust as you see fit */
    padding-right: 24px;
}