.cMapdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin:5px;
  z-index: '1 !important';
}

.cGenPicker{
  align-items: center;
  text-align: center;
  width: 100%;
  z-index: '99999';
}

.cInterceptor{
  align-items: center;
  text-align: center;
  width: 25%;
}

.cLoading{
  align-items: center;
  text-align: center;
  width: 20%;
}

.cInput{
  margin-top: 5px;
  text-align: right !important;
  align-items: right !important;
  border-style:none;
  border-color: gray !important;
  width: 80px;
}

.cTable{
  border-radius:10px;
  border-style: solid;
  border-color: lightgray;
  padding: 5px;
  width: 40%;
}

.custom-marker {
  filter: none;
}

.custom-marker img {
  filter: inherit;
}